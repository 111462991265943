import is, { hasProperty } from "@form-create/utils/lib/type";
import { parseFn } from "@form-create/utils/lib/json";

export function makeRequiredRule() {
    return {
        type: "Required",
        field: "formCreate$required",
        title: "是否必填",
    };
}

export function createName() {
    return {
        type: "input",
        field: "formCreateName",
        title: "编号",
    };
}
export function dataSource() {
    return {
        type: "input",
        field: "dataSource",
        title: "数据源",
    };
}
export function viewDataSource() {
    return {
        type: "input",
        field: "viewDataSource", //用于表格,树和表格
        title: "内容数据源",
    };
}
export function componentName() {
    return {
        type: "input",
        field: "name",
        title: "组件编号",
    };
}
export function field() {
    return {
        type: "input",
        field: "field",
        value: "",
        title: "字段 ID",
    };
}

function getOptionRule(optionRules, seq) {
    const obj = optionRules.find((item) => item.seq === seq);
    return obj ? obj.field : "";
}

export function makeOptionsRule(optionRules, title) {
    const seqs = optionRules.map((item) => item.seq);
    const showSeq = optionRules.find((item) => item.defaultShow).seq;
    const showRules = [
        {
            seq: 0,
            option: { label: "静态数据", value: 0 },
            control: {
                value: 0,
                rule: [
                    {
                        type: "TableOptions",
                        // 组件接收的字段
                        field: getOptionRule(optionRules, 0),
                        props: { defaultValue: [] },
                    },
                ],
            },
        },
        {
            seq: 1,
            option: { label: "JSON数据", value: 1 },
            control: {
                value: 1,
                rule: [
                    {
                        type: "Struct",
                        field: getOptionRule(optionRules, 1),
                        props: { defaultValue: [] },
                    },
                ],
            },
        },
        {
            seq: 2,
            option: { label: "接口数据", value: 2 },
            control: {
                value: 2,
                rule: [
                    {
                        type: "Fetch",
                        field: getOptionRule(optionRules, 2),
                        props: {
                            field: getOptionRule(optionRules, 2),
                        },
                    },
                ],
            },
        },
    ];

    const tempOptionsRules = showRules.filter((item) => seqs.indexOf(item.seq) > -1);
    const options = tempOptionsRules.map((item) => item.option);
    const controls = tempOptionsRules.map((item) => item.control);
    // console.log(":", {
    //     type: "radio",
    //     title: title ? title : "选项数据",
    //     field: "_optionType",
    //     value: showSeq,
    //     options,
    //     props: {
    //         type: "button",
    //     },
    //     info: '参考例子:[{label:"例子",value:"lizi"},{label:"实例",value:"shili"}]',
    //     control: controls,
    // });
    return {
        type: "radio",
        title: title ? title : "选项数据",
        field: "_optionType",
        value: showSeq,
        options,
        props: {
            type: "button",
        },
        control: controls,
    };
}

export function upper(str) {
    return str.replace(str[0], str[0].toLocaleUpperCase());
}

export const toJSON = function (val) {
    const type = /object ([a-zA-Z]*)/.exec(Object.prototype.toString.call(val));
    if (type && _toJSON[type[1].toLowerCase()]) {
        return _toJSON[type[1].toLowerCase()](val);
    } else {
        return val;
    }
};

const _toJSON = {
    object: function (val) {
        var json = [];
        for (var i in val) {
            if (!hasProperty(val, i)) continue;
            json.push(toJSON(i) + ": " + (val[i] != null ? toJSON(val[i]) : "null"));
        }
        return "{\n " + json.join(",\n ") + "\n}";
    },
    array: function (val) {
        for (var i = 0, json = []; i < val.length; i++) json[i] = val[i] != null ? toJSON(val[i]) : "null";
        return "[" + json.join(", ") + "]";
    },
    string: function (val) {
        var tmp = val.split("");
        for (var i = 0; i < tmp.length; i++) {
            var c = tmp[i];
            c >= " " ? (c === "\\" ? (tmp[i] = "\\\\") : c === '"' ? (tmp[i] = '\\"') : 0) : (tmp[i] = c === "\n" ? "\\n" : c === "\r" ? "\\r" : c === "\t" ? "\\t" : c === "\b" ? "\\b" : c === "\f" ? "\\f" : ((c = c.charCodeAt()), "\\u00" + (c > 15 ? 1 : 0) + (c % 16)));
        }
        return '"' + tmp.join("") + '"';
    },
};

export const deepParseFn = function (target) {
    for (let key in target) {
        if (Object.prototype.hasOwnProperty.call(target, key)) {
            let data = target[key];
            if (Array.isArray(data) || is.Object(data)) {
                deepParseFn(data);
            }
            if (is.String(data)) {
                target[key] = parseFn(data);
            }
        }
    }
    return target;
};
