import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import vueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import formCreate from "@form-create/element-ui";
import FcDesigner from "@form-create/designer";
import { designerForm } from "@form-create/designer";
import ELEMENT from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueWechatTitle from "vue-wechat-title";
import store from "./store";

import yesStruct from "./components/yesStruct";
import yesWorkbookEdit from "./components/yesWorkbookEdit";

designerForm.component("yesStruct", yesStruct);
designerForm.component("yesWorkbookEdit", yesWorkbookEdit);

const path = require("path");
const rules = require.context("./config/rule", false, /.js$/); //所有规则
const ruleNames = rules
    .keys()
    .map((key) => path.basename(key, ".js"))
    .filter((name) => name.startsWith("yes")); //规则文件名数组
const components = require.context("./components/", false, /.vue$/); //所有组件
const filterNames = ["yesStruct", "yesWorkbookEdit"]; //要过滤掉的组件
components.keys().forEach((key) => {
    const name = path.basename(key, ".vue");
    const content = components(key).default || components(key); //返回文件实例
    if (!(filterNames.indexOf(name) > -1) && ruleNames.indexOf(name) > -1) {
        //以yes开头的组件才会被引入
        const ruleKey = rules.keys().find((key) => path.basename(key, ".js").indexOf(name) > -1);
        const rule = rules(ruleKey).default || rules(ruleKey);
        Vue.component(rule.name, content);
    }
});

Vue.use(Vuex);
Vue.use(ELEMENT);
Vue.use(formCreate);
Vue.use(FcDesigner);
Vue.use(vueAxios, axios);
Vue.use(VueWechatTitle);
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");
