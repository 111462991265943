const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
import formCreate from "@form-create/element-ui";
export default {
  data() {
    return {
      /**
       * 导入脚本模板
       */
       showExampleScriptDialog: false,
       selectRowScript: null,
       
       /**
        * 导入规则模板
        */
       showExampleRuleDialog: false,
       searchData: {
         code: "",
       },
       selectRow: null,
       tableData: [],
       exampleTable: {
         app: "模块",
         code: "编号",
         name: "名称",
         description: "描述",
       },
       total: 0,
       pageSize: 10,
       pageNum: 1,
       /**
        * 导入视图规则
        */
       showViewRuleDialog: false,
       viewSearchData: {
         app: "",
       },
       viewTable: {
         app: "模块",
         code: "编号",
         name: "名称",
         description: "描述",
       },
       viewSelectRow: null,
       fields: [],
       /**
        * 导入模型规则
        */
       showModelRuleDialog: false,
       modelSelectRow: null,
       modelSearchData: {
         app: "",
       },
       modelTable: {
         app: "模块",
         code: "编号",
         name: "名称",
         description: "描述",
       },
       fieldDialogVisible: false,
       dataLists: [],
       componentsDefaultProps: {
         children: "children",
         label: "showName",
       },
    };
  },
  methods: {
    clearInput () {
      this.clearFocusIdAndNodeClickData();
    },// 循环设置组件定位弹出框的组件显示名称,设置组件index
    iterateOverAddingShowFields (rule, idx, path) {
      let name = rule.name || rule.field || "";
      rule.showName = `${name} (${rule.type})`;
      if (path) {
        rule.index = path + "-" + idx;
      } else {
        rule.index = idx;
      }
      if (rule.children && rule.children.length > 0) {
        let childrenIdx = 0;
        rule.children.forEach((item) => {
          childrenIdx++;
          this.iterateOverAddingShowFields(item, childrenIdx, rule.index);
        });
      }
    },
    // 组件过滤
    filterNode (value, data) {
      if (!value) return true;
      return data.name ? data.name.indexOf(value) !== -1 : false;
    },
    // 打开剪贴板
    componentsPositionDialog () {
      this.clearFocusIdAndNodeClickData();
      let rules = this.$refs.designer.getRule();
      let idx = 0;
      rules.forEach((item) => {
        idx++;
        this.iterateOverAddingShowFields(item, idx);
      });
      this.dataLists = rules;
      this.fieldDialogVisible = !this.fieldDialogVisible;
    },
    // 组件定位组件选择事件
    componentsHandleNodeClick (data) {
      if (!(data.name || data.field)) {
        return utils.messageError("编号为空,请对相应组件设置编号!", 1000);
      }
      this.focusId = data.name || data.field || "";
      delete data.showName;
      this.nodeClickData = data;
      this.focusSearch();
      this.fieldDialogVisible = !this.fieldDialogVisible;
    },
    // 清空剪贴板/focusId/nodeClickData
    clearFocusIdAndNodeClickData () {
      this.focusId = "";
      this.nodeClickData = null;
    },
    onCopy () {
      if (!this.focusId) {
        this.clearFocusIdAndNodeClickData();
        return utils.messageError("请选择要复制的组件", 1000);
      }
      const rules = this.$refs.designer.getRule();
      var json = [];
      rules.forEach((rule) => {
        let current = this.getTheSpecifiedComponent(rule);
        if (
          current &&
          (current.name === this.focusId || current.field === this.focusId)
        ) {
          json.push(current);
        }
      });
      var copyText = JSON.stringify(json);
      navigator.clipboard.writeText(copyText).then(() => {
        this.focusId = "";
        utils.messageSuccess("复制成功", 1000);
      });
    },
    onInsert () {
      const _this = this;
      let rules = this.$refs.designer.getRule();
      let idx = 0;
      rules.forEach((item) => {
        idx++;
        this.iterateOverAddingShowFields(item, idx);
      });
      let rule = null;
      navigator.clipboard.readText().then((clipText) => {
        // 如果不是json格式的字符串，则返回
        if (!_this.isJsonString(clipText)) {
          return utils.messageError("插入的内容不是json格式", 1000);
        }
        if (_this.nodeClickData) {
          for (var i = 0; i < rules.length; i++) {
            rule = _this.getTheSpecifiedComponentByIndex(
              rules[i],
              _this.nodeClickData.index
            );
            if (rule && rule.index === _this.nodeClickData.index) {
              break;
            }
          }
          if (rule) {
            let flag = this.judgeTheRuleCanInsert(rule);
            var insertRule = JSON.parse(clipText)[0];
            if (flag) {
              if (rule.children) {
                rule.children.push(insertRule);
              } else {
                rule["children"] = [];
                rule.children.push(insertRule);
              }
              _this.$refs.designer.setRule(rules);
            } else {
              let newRule = _this.$refs.designer.getRule().concat(insertRule);
             _this.$refs.designer.setRule(newRule);
            }
          } else {
            rule = JSON.parse(clipText);
            let newRule = _this.$refs.designer.getRule().concat(rule);
            _this.$refs.designer.setRule(newRule);
          }
        } else {
          rule = JSON.parse(clipText);
          let newRule = _this.$refs.designer.getRule().concat(rule);
          _this.$refs.designer.setRule(newRule);
        }
      });
    },
    // 判定选择的组件是否可以插入
    judgeTheRuleCanInsert(rule) {
      let flag = false
      switch (rule._fc_drag_tag) {
        case "yes-collapse-item":
        case "yes-div":
        case "yes-row-col":
        case "yes-tabs-pane":
          flag = true;
          break;
        default:
          flag = false;
          break;
      }
      return flag;
    },
    // 判断是否是json格式的字符串
    isJsonString (str) {
      try {
        if (typeof JSON.parse(str) == "object") {
          return true;
        }
      } catch (e) {
        return false;
      }
    },
    getTheSpecifiedComponentByIndex (rule, index) {
      if (rule.index === index) {
        return rule;
      } else if (rule.children) {
        for (var i = 0; i < rule.children.length; i++) {
          var current = this.getTheSpecifiedComponentByIndex(
            rule.children[i],
            index
          );
          if (current && current.index === index) {
            return current;
          }
        }
      }
    },
    getTheSpecifiedComponent (rule) {
      if (rule.name === this.focusId || rule.field === this.focusId) {
        return rule;
      } else if (rule.children) {
        for (var i = 0; i < rule.children.length; i++) {
          var current = this.getTheSpecifiedComponent(rule.children[i]);
          if (current) {
            return current;
          }
        }
      }
    },
    /**
     * 导入脚本
     */
    async downloadExampleScript () {
      this.showExampleScriptDialog = true;
      await this.getExampleScript();
    },
    async getExampleScript () {
      this.searchData.type = "SCRIPT";
      const res = await dao.view(
        "fde",
        "example",
        this.searchData,
        this.pageNum,
        this.pageSize
      );
      this.tableData = res.data;
      this.total = res.count;
    },
    async exampleScriptSearch () {
      this.pageNum = 1;
      await this.getExampleScript();
    },
    handleRowScriptClick (val) {
      this.selectRowScript = val;
    },
    handleCurrentScriptChange (val) {
      this.pageNum = val;
      this.getExampleScript();
    },
    addExampleScriptTOClipboard () {
      if (!this.selectRowScript) {
        return utils.messageError("请选择一条数据", 1000);
      }
      if (this.selectRowScript.type === "SCRIPT") {
        navigator.clipboard.writeText(this.selectRowScript.script).then(() => {
          utils.messageSuccess("复制成功", 1000);
        });
      }
      this.downloadRuleClose()
    },
    /**
     * 导入规则
     */
    async downloadExampleRule () {
      this.showExampleRuleDialog = true;
      await this.getExampleRule();
    },
    async getExampleRule () {
      this.searchData.type = "RULE";
      const res = await dao.view(
        "fde",
        "example",
        this.searchData,
        this.pageNum,
        this.pageSize
      );
      this.tableData = res.data;
      this.total = res.count;
    },
    async exampleRuleSearch () {
      this.pageNum = 1;
      await this.getExampleRule();
    },
    handleRowClick (val) {
      this.selectRow = val;
    },
    handleCurrentChange (val) {
      this.pageNum = val;
      this.getExampleRule();
    },
    addExampleRuleTORule () {
      if (!this.selectRow) {
        return utils.messageError("请选择一条数据", 1000);
      }
      if (this.selectRow.type === "RULE") {
        let val = JSON.parse(this.selectRow.script);
        if (!Array.isArray(val)) {
          this.err = true;
          return;
        }
        var newRule = formCreate.parseJson(this.selectRow.script);
        // var oldRule = this.$refs.designer.getRule();
        // var nowRule = oldRule.concat(newRule);
        // this.$refs.designer.setRule(nowRule);
        this.addImportRules(newRule);
      }
    },

    /**
     * 导入视图规则
     */
    downloadViewRule () {
      this.showViewRuleDialog = true;
      this.getView();
    },
    async getView () {
      const res = await dao.view(
        "dme",
        "view",
        this.viewSearchData,
        this.pageNum,
        this.pageSize
      );
      this.tableData = res.data;
      this.total = res.count;
    },
    async viewSearch () {
      this.pageNum = 1;
      await this.getView();
    },
    async handleViewRowClick (val) {
      this.viewSelectRow = val;
    },
    handleCurrentViewChange (val) {
      this.pageNum = val;
      this.getView();
    },
    async addViewToRule () {
      if (!this.viewSelectRow) {
        return utils.messageError("请选择一条数据", 1000);
      }
      const data = {
        app: this.viewSelectRow.app,
        view: this.viewSelectRow.code,
      };
      const res = await dao.view("dme", "column", data);
      this.fields = res.data;

      let needAddRule = this.viewOuterLayerContainer();
      let needAddRuleChildren = needAddRule.children;
      let dataSourceRule = this.dataSourceRule();
      needAddRuleChildren.push(dataSourceRule);
      let tableRule = this.tableRule();
      this.fields.forEach((item) => {
        let children = this.tableColumnRule(item);
        tableRule.children.push(children);
      });
      needAddRuleChildren.push(tableRule);
      let paginationContainer = this.paginationContainer();
      needAddRuleChildren.push(paginationContainer);
      this.addImportRules(needAddRule);
    },
    viewOuterLayerContainer () {
      return {
        type: "yes-div",
        name: `${this.viewSelectRow.code}_view_table_div`,
        props: {
          hiddenInit: false,
          backImg: false,
          backShowType: "repeat",
          flexDirection: "column",
          justifyValue: "none",
          alignValue: "none",
          coverType: "all",
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
          definedCss: "border: 1px solid #eee;",
          marginTopValue: "10px",
          marginBottomValue: "10px",
          marginLeftValue: "10px",
          marginRightValue: "10px",
        },
        children: [],
        _fc_drag_tag: "yes-div",
        hidden: false,
        display: true,
      };
    },
    dataSourceRule () {
      return {
        name: `${this.viewSelectRow.code}_view_data`,
        type: "yes-dataSource",
        props: {
          type: "view",
          firstLoad: true,
          initData: {},
          appCode: this.viewSelectRow.app,
          viewCode: this.viewSelectRow.code,
        },
        _fc_drag_tag: "yes-dataSource",
        hidden: false,
        display: true,
      };
    },
    tableRule () {
      return {
        type: "yes-table",
        name: `${this.viewSelectRow.code}_view_table`,
        title: "表格",
        props: {
          dataSource: "",
          viewDataSource: `${this.viewSelectRow.code}_view_data`,
          size: "mini",
          border: true,
          highlightCurrentRow: true,
        },
        children: [],
        _fc_drag_tag: "yes-table",
        hidden: false,
        display: true,
      };
    },
    // 表格列
    tableColumnRule (item) {
      return {
        type: "yes-table-column",
        name: item.alias,
        title: "表格列",
        props: {
          prop: item.alias,
          label: item.name,
          align: "center",
        },
        _fc_drag_tag: "yes-table-column",
        hidden: false,
        display: true,
      };
    },
    // 分页容器
    paginationContainer () {
      return {
        type: "yes-div",
        name: `${this.viewSelectRow.code}_view_controller_div`,
        props: {
          hiddenInit: false,
          backImg: false,
          backShowType: "repeat",
          flexDirection: "column",
          justifyValue: "flex-start",
          alignValue: "flex-end",
          coverType: "all",
          height: "50px",
          paddingLeft: "10px",
        },
        children: [
          {
            type: "yes-pagination",
            name: `${this.viewSelectRow.code}_view_controller`,
            title: "分页",
            props: {
              dataSource: `${this.viewSelectRow.code}_view_data`,
            },
            _fc_drag_tag: "yes-pagination",
            hidden: false,
            display: true,
          },
        ],
        _fc_drag_tag: "yes-div",
        hidden: false,
        display: true,
      };
    },
    /**
     * 导入模型规则
     */
    async downloadModelRule () {
      this.showModelRuleDialog = true;
      await this.getModel();
    },
    async getModel () {
      const res = await dao.view(
        "dme",
        "model",
        this.modelSearchData,
        this.pageNum,
        this.pageSize
      );
      this.tableData = res.data;
      this.total = res.count;
    },
    async modelSearch () {
      this.pageNum = 1;
      await this.getModel();
    },
    async handleModelRowClick (val) {
      this.modelSelectRow = val;
    },
    handleCurrentModelChange (val) {
      this.pageNum = val;
      this.getModel();
    },
    async addModelToRule () {
      if (!this.modelSelectRow) {
        return utils.messageError("请选择一条数据", 1000);
      }
      const data = {
        app: this.modelSelectRow.app,
        model: this.modelSelectRow.code,
      };
      const res = await dao.view("dme", "field", data);
      this.fields = res.data;
      var fatherContainer = this.formOuterLayerDiv();
      let dataSourceRule = this.formDataSource(); // 数据源
      fatherContainer.children.push(dataSourceRule); // 添加数据源
      var middleContainer = this.formMiddleDiv();
      var sonContainer = null;
      var _this = this;
      this.fields.forEach((item, index) => {
        sonContainer = this.formSonDiv(item.code, item.name);
        middleContainer.children.push(sonContainer);
        if (index % 2 === 1) {
          fatherContainer.children.push(middleContainer);
          middleContainer = this.formMiddleDiv();
        } else if (index % 2 === 0 && index === _this.fields.length - 1) {
          fatherContainer.children.push(middleContainer);
        }
      });
      this.addImportRules(fatherContainer);
    },
    // 表单弹出框
    formDialog () {
      return {
        type: "yes-dialog",
        name: `${this.modelSelectRow.code}_model_dialog`,
        children: [],
        _fc_drag_tag: "yes-dialog",
        hidden: false,
        display: true,
      };
    },
    // 表单外层容器
    formOuterLayerDiv () {
      return {
        type: "yes-div",
        name: `${this.modelSelectRow.code}_model_dialog_div`,
        props: {
          hiddenInit: false,
          backImg: false,
          backShowType: "repeat",
          flexDirection: "column",
          justifyValue: "none",
          alignValue: "center",
          coverType: "all",
          width: "",
          height: "",
          marginTopValue: "10px",
          paddingTop: "10px",
          marginBottomValue: "10px",
          marginLeftValue: "10px",
          marginRightValue: "10px",
          definedCss: "border: 1px solid #eee;",
          paddingBottom: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
        },
        _fc_drag_tag: "yes-div",
        hidden: false,
        display: true,
        children: [],
      };
    },
    // 表单默认按钮
    formButtonDiv () {
      return {
        type: "yes-div",
        name: `${this.modelSelectRow.code}_model_dialog_form_button`,
        props: {
          hiddenInit: false,
          backImg: false,
          backShowType: "repeat",
          flexDirection: "row",
          justifyValue: "flex-start",
          alignValue: "center",
          coverType: "all",
          height: "60px",
          paddingTop: "",
          paddingLeft: "10px",
          paddingRight: "10px",
          definedCss: "border-bottom: 1px solid #eee;",
        },
        children: [
          {
            type: "yes-div",
            name: `${this.modelSelectRow.code}_model_dialog_form_button_div1`,
            props: {
              hiddenInit: false,
              backImg: false,
              backShowType: "repeat",
              flexDirection: "column",
              justifyValue: "none",
              alignValue: "none",
              coverType: "all",
              position: "adaptive",
              width: "100px",
              widthMin: "",
              marginLeftValue: "10px",
              marginRightValue: "10px",
            },
            children: [
              {
                type: "yes-button",
                name: `${this.modelSelectRow.code}_button_add`,
                props: {
                  content: "添加",
                  size: "small",
                  type: "primary",
                },
                _fc_drag_tag: "yes-button",
                hidden: false,
                display: true,
              },
            ],
            _fc_drag_tag: "yes-div",
            hidden: false,
            display: true,
          },
          {
            type: "yes-div",
            name: `${this.modelSelectRow.code}_model_dialog_form_button_div2`,
            props: {
              hiddenInit: false,
              backImg: false,
              backShowType: "repeat",
              flexDirection: "column",
              justifyValue: "none",
              alignValue: "none",
              coverType: "all",
              position: "adaptive",
              width: "100px",
              widthMin: "",
              marginLeftValue: "10px",
              marginRightValue: "10px",
            },
            children: [
              {
                type: "yes-button",
                name: `${this.modelSelectRow.code}_button_edit`,
                props: {
                  content: "编辑",
                  size: "small",
                  type: "primary",
                },
                _fc_drag_tag: "yes-button",
                hidden: false,
                display: true,
              },
            ],
            _fc_drag_tag: "yes-div",
            hidden: false,
            display: true,
          },
          {
            type: "yes-div",
            name: `${this.modelSelectRow.code}_model_dialog_form_button_div3`,
            props: {
              hiddenInit: false,
              backImg: false,
              backShowType: "repeat",
              flexDirection: "column",
              justifyValue: "none",
              alignValue: "none",
              coverType: "all",
              position: "adaptive",
              width: "100px",
              widthMin: "",
              marginLeftValue: "10px",
              marginRightValue: "10px",
            },
            children: [
              {
                type: "yes-button",
                name: `${this.modelSelectRow.code}_button_delete`,
                props: {
                  content: "删除",
                  size: "small",
                  type: "danger",
                },
                _fc_drag_tag: "yes-button",
                hidden: false,
                display: true,
              },
            ],
            _fc_drag_tag: "yes-div",
            hidden: false,
            display: true,
          },
        ],
        _fc_drag_tag: "yes-div",
        hidden: false,
        display: true,
      };
    },
    // 表单绑定数据源
    formDataSource () {
      return {
        name: `${this.modelSelectRow.code}_model_data`,
        type: "yes-dataSource",
        props: {
          type: "model",
          appCode: this.modelSelectRow.app,
          model: this.modelSelectRow.code,
          firstLoad: false,
        },
        _fc_drag_tag: "yes-dataSource",
        hidden: false,
        display: true,
      };
    },
    // 表单行容器
    formMiddleDiv () {
      return {
        type: "yes-div",
        name: `${this.modelSelectRow.code}_model_data_div`,
        props: {
          hiddenInit: false,
          backImg: false,
          backShowType: "repeat",
          flexDirection: "row",
          justifyValue: "flex-start",
          alignValue: "none",
          coverType: "all",
          width: "",
          height: "60px",
          definedCss: "border-bottom: 1px solid #eee;",
        },
        children: [],
        _fc_drag_tag: "yes-div",
        hidden: false,
        display: true,
      };
    },
    // 表单行容器中的列容器
    formSonDiv (code, name) {
      return {
        type: "yes-div",
        name: `${this.modelSelectRow.code}_model_data_div_form_div`,
        props: {
          hiddenInit: false,
          backImg: false,
          backShowType: "repeat",
          flexDirection: "column",
          justifyValue: "none",
          alignValue: "flex-end",
          coverType: "all",
          marginLeftValue: "5px",
          marginRightValue: "5px",
          paddingLeft: "20px",
          paddingRight: "20px"
        },
        children: [
          {
            field: code,
            title: name,
            type: "yes-input",
            info: "",
            props: {
              dataSource: `${this.modelSelectRow.code}_model_data.${code}`,
              placeholder: `请输入${name}`,
            },
            _fc_drag_tag: "yes-input",
            hidden: false,
            display: true,
          },
        ],
        _fc_drag_tag: "yes-div",
        hidden: false,
        display: true,
      };
    },
    // 导入
    addImportRules (needAddRule) {
      if (this.nodeClickData) {
        let rules = this.$refs.designer.getRule();
        let rule = null;
        let idx = 0;
        const _this = this;
        rules.forEach((item) => {
          idx++;
          _this.iterateOverAddingShowFields(item, idx);
        });
        for (var i = 0; i < rules.length; i++) {
          rule = _this.getTheSpecifiedComponentByIndex(
            rules[i],
            _this.nodeClickData.index
          );
          if (rule && rule.index === _this.nodeClickData.index) {
            break;
          }
        }
        if (rule) {
          if (rule.children) {
            if (!Array.isArray(needAddRule)) {
              rule.children.push(needAddRule);
            } else {
              needAddRule.forEach((item) => {
                rule.children.push(item);
              });
            }
          } else {
            rule["children"] = [];
            if (!Array.isArray(needAddRule)) {
              rule.children.push(needAddRule);
            } else {
              rule.children = needAddRule;
            }
          }
        } else {
          if(rules.length === 0) {
            rules = needAddRule;
          } else {
            rules.concat(needAddRule);
          }
        }
        this.$refs.designer.setRule(rules);
        utils.messageSuccess("导入成功", 1000);
      } else {
        const currentRule = this.$refs.designer.getRule().concat(needAddRule);
        this.$refs.designer.setRule(currentRule);
        utils.messageSuccess("导入成功", 1000);
      }
      this.downloadRuleClose();
    },
    /**
     * 关闭 导入模型规则/导入视图规则/导入规则模板
     */
    downloadRuleClose () {
      this.tableData = [];
      this.searchData = {};
      this.viewSearchData = {};
      this.modelSearchData = {};
      this.selectRow = null;
      this.selectRowScript = null;
      this.viewSelectRow = null;
      this.modelSelectRow = null;
      this.fields = [];
      this.total = 0;
      this.pageNum = 1;
      this.pageSize = 10;
      this.showExampleRuleDialog = false;
      this.showExampleScriptDialog = false;
      this.showViewRuleDialog = false;
      this.showModelRuleDialog = false;
    },
  },
};
