const state = {
    permissions: [],
};

const mutations = {
    SETPERMISSIONS: (state, permissions) => {
        state.permissions = permissions;
    },
    REMOVEPERMISSIONS: (state) => {
        state.permissions = [];
    },
};

const actions = {
    setPermissions({ commit }, permissions) {
        commit("SETPERMISSIONS", permissions);
    },
    removePermissions({ commit }) {
        commit("REMOVEPERMISSIONS");
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
