const getters = {
  api: (state) => state.api.api,
  header: (state) => state.header.header,
  config: (state) => state.config.config,
  router: (state) => state.config.config.router,
  page: (state) => state.config.config.page,
  user:(state)=>state.info.user,
  permissions: (state)=> state.permissions.permissions,
}
export default getters
