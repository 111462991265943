const state = {
    header:{}
  }
  
  const mutations = {
    SETHEADER: (state, header) => {
      state.header = header
    },
    REMOVEHEADER: (state) =>{
        state.header = {}
    }
  }
  
  const actions = {
    setHeader({ commit }, header) {
      commit('SETHEADER', header)
    },
    removeHeader({ commit }) {
        commit('REMOVEHEADER')
    }
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  