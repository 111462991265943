const state = {
    config:{
      router: {},
      page:{},
      iam: {}
    }
  }
  
  const mutations = {
    SETCONFIG: (state, config) => {
      state.config = config
    },
    SETROUTER: (state, router) => {
      state.config.router = router
    },
    SETPAGE: (state, page) => {
      state.config.page = page
    },
    SETIAM: (state, iam) => {
      state.config.iam = iam
    }
  }
  
  const actions = {
    setConfig({ commit }, config) {
      commit('SETCONFIG', config)
    },
    setRouter({ commit }, router) {
      commit('SETROUTER', router)
    },
    setPage({ commit }, page) {
      commit('SETPAGE', page)
    },
    setIam({ commit }, iam) {
      commit('SETIAM', iam)
    }
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  