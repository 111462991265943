const moment = require('moment');

//自己定制要转换的格式
export function defineFormatDate(datetime,format){
  return moment(datetime).format(format)
}


export function formatDateTime(datetime){
  return moment(datetime).format('YYYY-MM-DD HH:mm:ss')
}



export function formatDate(datetime){
  return moment(datetime).format('HH:mm:ss')
}



export function formatTime(datetime){
  return moment(datetime).format('YYYY-MM-DD')
}


