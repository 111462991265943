<template>
  <div id="app">
    <div class="yes-de-header" style="width: 100%;">
      <div style="
            display: flex;
            align-items: center;
            margin-right: 10px;
            z-index: 100;
            width:100%;
            margin-left:167px;
          ">
        <div style="display: flex;align-items: center; margin-right: 10px;">
          <div class="yes-menu-search">
            <el-input placeholder="请输入组件名称" v-model="search.name" style="width: 246px" clearable @input="handleSearch"
              id="search">
            </el-input>
          </div>
          <el-button @click="his_previous" :disabled="his_operateHistoryIndex < 0" size="small">撤销</el-button>
          <el-button @click="his_next" size="small" :disabled="his_operateHistoryIndex >= his_componentHistory.length - 1
            ">恢复</el-button>
        </div>
        <div class="yes-menu-main">
          <el-button size="mini" type="primary" @click="downloadExampleScript">脚本示例</el-button>
          <el-button size="mini" type="primary" @click="showSaveModal">保存</el-button>
          <!-- <el-button
            @click="printDesign"
            size="small"
          >打印</el-button> -->
          <el-button size="mini" type="primary" @click="openExample">打开</el-button>
          <el-button size="mini" icon="fc-icon icon-import" @click="setJson">
            导入JSON</el-button>
          <el-button size="mini" icon="fc-icon icon-import" @click="setOption">
            导入Options</el-button>
          <el-button size="mini" type="primary" @click="showJson">生成JSON</el-button>
          <el-button size="mini" type="success" @click="showOption">生成Options</el-button>
        </div>
      </div>
    </div>
    <div class="script-type">
      <el-tabs ref="tabs" v-model="scr_activeName" type="card" @tab-click="mainTabClick">
        <el-tab-pane label="设计" name="design">
          <fc-designer ref="designer" :menu="menuList" @hook:mounted="hookHanlder">
            <template slot="handle">
              <el-input placeholder="选取组件" v-model="focusId" style="width: 200px; margin-right: 10px" clearable
                size="mini" @clear="clearInput" @focus="componentsPositionDialog"></el-input>
              <el-button type="success" plain icon="el-icon-document-copy" round size="mini"
                @click="onCopy">复制</el-button>
              <el-button type="success" plain icon="el-icon-document-add" round size="mini"
                @click="onInsert">粘贴</el-button>
              <el-button type="success" plain icon="el-icon-download" round size="mini"
                @click="downloadExampleRule">规则</el-button>
              <el-button type="success" plain icon="el-icon-download" round size="mini"
                @click="downloadViewRule">视图</el-button>
              <el-button type="success" plain icon="el-icon-download" round size="mini"
                @click="downloadModelRule">模型</el-button>
              <el-button type="success" plain icon="el-icon-refresh" round size="mini" @click="reLoadJosn">刷新</el-button>
            </template>
          </fc-designer>
          <el-dialog title="组件定位" :visible.sync="fieldDialogVisible" width="50%" style="margin-top: -8vh" :modal="false"
            :close-on-click-modal="true">
            <el-input v-model="focusId" placeholder="请输入组件编号过滤" clearable style="width: 100%; margin-bottom: 20px"
              @input="focusSearch"></el-input>
            <el-scrollbar style="height: 400px">
              <el-tree ref="componentsTreeRef" :data="dataLists" default-expand-all node-key="index" highlight-current
                :props="componentsDefaultProps" @node-click="componentsHandleNodeClick"
                :filter-node-method="filterNode"></el-tree>
            </el-scrollbar>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="脚本" name="script">
          <div class="script-main">
            <div class="script-tabs">
              <el-input placeholder="请输入组件编号或字段ID快速定位" v-model="scr_componentName" style="width: 200px; margin: 10px 10px"
                clearable size="mini" @clear="scr_clearSearch" @keyup.enter.native="scr_sreachScript()"></el-input>
              <el-tabs v-model="scr_scriptTypeTabs" type="card" @tab-click="scriptTypeTabClick" style="margin: 10px">
                <el-tab-pane label="按组件" name="components">
                  <el-tree ref="type_treeRef" :data="scr_typeTreeData" :props="scr_defaultProps" node-key="name"
                    @node-click="handleNodeClick" :default-expanded-keys="scr_expanded"></el-tree>
                </el-tab-pane>
                <el-tab-pane label="按事件" name="event">
                  <el-tree ref="event_treeRef" :data="scr_eventTreeData" node-key="name" :props="scr_defaultProps"
                    @node-click="handleNodeEventClick" :default-expanded-keys="scr_expanded"></el-tree>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="script-editor">
              <div class="script-editor-tabs">
                <el-tabs type="card" v-model="scr_scriptActiveName" @tab-click="scriptTabClick" closable
                  @tab-remove="removeTab">
                  <el-tab-pane v-for="item in scr_editableTabs" :key="item.name" :label="item.title" :name="item.name">
                    <div style="
                          min-height: 600px;
                          width: 100%;
                          border: 1px solid #eee;
                        ">
                      <monaco-edit :height="600" ref="scr_monacoRef" :options="scr_options" @change="monacoChange"
                        :value="scr_monacoContent">
                      </monaco-edit>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <yes-desginer
        ref="designer"
        :menu="menuList"
      /> -->

    <!-- <previewDialog
        :show="dialogShow"
        :options="tempOptions"
        :rule="tempRules"
        @finish="dialogClose"
      /> -->
    <el-dialog :title="title[type]" :visible.sync="state" class="_fc-t-dialog">
      <div ref="editor" v-if="state"></div>
      <span style="color: red" v-if="err">输入内容格式有误!</span>
      <span slot="footer" class="dialog-footer" v-if="type > 2">
        <el-button @click="state = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onOk" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 保存数据时使用 -->
    <el-dialog title="保存组件" :visible.sync="showSaveDialog" class="_fc-t-dialog" top="2vh">
      <el-form ref="componentForm" :model="componentData" label-width="100px" :rules="rules">
        <el-form-item label="主键">
          <el-input v-model="componentData.uid"></el-input>
        </el-form-item>
        <el-form-item label="应用编号" prop="app">
          <el-input v-model="componentData.app"></el-input>
        </el-form-item>
        <el-form-item label="组件编号" prop="code">
          <el-input v-model="componentData.code"></el-input>
        </el-form-item>
        <el-form-item label="组件名称" prop="name">
          <el-input v-model="componentData.name"></el-input>
        </el-form-item>
        <el-form-item label="上级组件应用">
          <el-input v-model="componentData.parent_app"></el-input>
        </el-form-item>
        <el-form-item label="上级组件编号">
          <el-input v-model="componentData.parent_code"></el-input>
        </el-form-item>
        <el-form-item label="上级组件位置">
          <el-input v-model="componentData.parent_component"></el-input>
        </el-form-item>
        <el-form-item label="组件类型">
          <el-select v-model="componentData.type">
            <el-option v-for="item in componentTypes" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="colseShowSaveModal" size="small">取 消</el-button>
        <el-button type="primary" @click="saveForm" size="small" :loading="saveLoading">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 脚本模板 -->
    <el-dialog title="导入脚本示例" width="60%" :visible.sync="showExampleScriptDialog" class="_fc-t-dialog" :modal="false"
      @close="downloadRuleClose">
      <div>
        <el-form ref="scriptSearchForm" class="demo-form-inline" :model="searchData" label-width="80px" :inline="true">
          <el-form-item :label="`${exampleTable.code}/${exampleTable.name}`">
            <el-input v-model="searchData.code" :placeholder="`请输入${exampleTable.code}/${exampleTable.name}`"
              @input="exampleScriptSearch" clearable />
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table :data="tableData" height="350px" border @row-click="handleRowScriptClick" highlight-current-row>
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column :label="exampleTable.app" prop="app" align="center" show-overflow-tooltip />
          <el-table-column :label="exampleTable.code" prop="code" align="center" show-overflow-tooltip />
          <el-table-column :label="exampleTable.name" prop="name" align="center" show-overflow-tooltip />
          <el-table-column :label="exampleTable.description" prop="description" align="center" show-overflow-tooltip />
        </el-table>
        <div style="margin-top: 25px">
          <el-pagination layout="total,prev, pager, next,jumper" :total="total" :page-size="pageSize"
            :current-page="pageNum" :hide-on-single-page="true" @current-change="handleCurrentScriptChange" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="display: flex; justify-content: end">
          <el-button @click="downloadRuleClose" size="small">取 消</el-button>
          <el-button type="primary" plain @click="addExampleScriptTOClipboard">
            复制到剪贴板
          </el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 规则模板 -->
    <el-dialog title="导入规则模板" width="60%" :visible.sync="showExampleRuleDialog" class="_fc-t-dialog" :modal="false"
      @close="downloadRuleClose">
      <div>
        <el-form ref="ruleSearchForm" class="demo-form-inline" :model="searchData" label-width="80px" :inline="true">
          <el-form-item :label="`${exampleTable.code}/${exampleTable.name}`">
            <el-input v-model="searchData.code" :placeholder="`请输入${exampleTable.code}/${exampleTable.name}`"
              @input="exampleRuleSearch" clearable />
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table :data="tableData" height="350px" border @row-click="handleRowClick" highlight-current-row>
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column :label="exampleTable.app" prop="app" align="center" show-overflow-tooltip />
          <el-table-column :label="exampleTable.code" prop="code" align="center" show-overflow-tooltip />
          <el-table-column :label="exampleTable.name" prop="name" align="center" show-overflow-tooltip />
          <el-table-column :label="exampleTable.description" prop="description" align="center" show-overflow-tooltip />
        </el-table>
        <div style="margin-top: 25px">
          <el-pagination layout="total,prev, pager, next,jumper" :total="total" :page-size="pageSize"
            :current-page="pageNum" :hide-on-single-page="true" @current-change="handleCurrentChange" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="display: flex; justify-content: end">
          <el-button @click="downloadRuleClose" size="small">取 消</el-button>
          <el-button type="primary" plain @click="addExampleRuleTORule">
            导入
          </el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 视图规则 -->
    <el-dialog title="导入视图规则" width="60%" :visible.sync="showViewRuleDialog" class="_fc-t-dialog" :modal="false"
      @close="downloadRuleClose">
      <div>
        <el-form ref="viewSearchForm" class="demo-form-inline" :model="viewSearchData" label-width="80px" :inline="true">
          <el-form-item :label="`${viewTable.code}/${viewTable.name}`">
            <el-input v-model="viewSearchData.code" :placeholder="`请输入${viewTable.code}/${viewTable.name}`"
              @input="viewSearch" clearable />
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table :data="tableData" height="350px" border @row-click="handleViewRowClick" highlight-current-row>
          <el-table-column :label="viewTable.app" prop="app" align="center" show-overflow-tooltip />
          <el-table-column :label="viewTable.code" prop="code" align="center" show-overflow-tooltip />
          <el-table-column :label="viewTable.name" prop="name" align="center" show-overflow-tooltip />
          <el-table-column :label="viewTable.description" prop="description" align="center" show-overflow-tooltip />
        </el-table>
        <div style="margin-top: 25px">
          <el-pagination layout="total,prev, pager, next,jumper" :total="total" :page-size="pageSize"
            :current-page="pageNum" :hide-on-single-page="true" @current-change="handleCurrentViewChange" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="display: flex; justify-content: end">
          <el-button @click="downloadRuleClose" size="small">取 消</el-button>
          <el-button type="primary" plain @click="addViewToRule">
            导入
          </el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 模型规则 -->
    <el-dialog title="导入模型规则" width="60%" :visible.sync="showModelRuleDialog" class="_fc-t-dialog" :modal="false"
      @close="downloadRuleClose">
      <div>
        <el-form ref="modelSearchForm" class="demo-form-inline" :model="modelSearchData" label-width="80px"
          :inline="true">
          <el-form-item :label="`${modelTable.code}/${modelTable.name}`">
            <el-input v-model="modelSearchData.code" :placeholder="`请输入${modelTable.code}/${modelTable.name}`"
              @input="modelSearch" clearable />
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table :data="tableData" height="350px" border @row-click="handleModelRowClick" highlight-current-row>
          <el-table-column :label="modelTable.app" prop="app" align="center" show-overflow-tooltip />
          <el-table-column :label="modelTable.code" prop="code" align="center" show-overflow-tooltip />
          <el-table-column :label="modelTable.name" prop="name" align="center" show-overflow-tooltip />
          <el-table-column :label="modelTable.description" prop="description" align="center" show-overflow-tooltip />
        </el-table>
        <div style="margin-top: 25px">
          <el-pagination layout="total,prev, pager, next,jumper" :total="total" :page-size="pageSize"
            :current-page="pageNum" :hide-on-single-page="true" @current-change="handleCurrentModelChange" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="display: flex; justify-content: end">
          <el-button @click="downloadRuleClose" size="small">取 消</el-button>
          <el-button type="primary" plain @click="addModelToRule">
            导入
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
// import jsonlint from 'jsonlint-mod';
// import { view } from "@/api/core/dao";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/lint/lint.css";
import CodeMirror from "codemirror/lib/codemirror";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/json-lint";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/vue/vue";
import "codemirror/mode/xml/xml";
import "codemirror/mode/css/css";
import "codemirror/addon/mode/overlay";
import "codemirror/addon/mode/simple";
import "codemirror/addon/selection/selection-pointer";
import "codemirror/mode/handlebars/handlebars";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/pug/pug";

import { getComponent, saveComponent } from "@/api/core/component";
import is from "@form-create/utils/lib/type";
import formCreate from "@form-create/element-ui";
import createMobileMenu from "../config/mobileMenu";
import createMenu from "../config/menu";
import form from "../config/base/mobile_form";
import monacoEdit from "../components/monacoEdit/index";
import history from "../mixin/history";
import script from "../mixin/script";
import import_example from "../mixin/import_example";
const dao = require("@/api/core/dao");
const utils = require("../api/core/utils");
const TITLE = [
  "生成规则",
  "表单规则",
  "生成组件",
  "设置生成规则",
  "设置表单规则",
];
const path = require("path");

export default {
  name: "App",
  components: { monacoEdit },
  mixins: [history, script, import_example],
  created() { },
  mounted() {

    document.getElementById("search").style.height = "32px";


    const files = require.context("../config/rule", false, /.js$/);
    files.keys().forEach((key) => {
      const name = path.basename(key, ".js");
      if (name.indexOf("yes") > -1) {
        const content = files(key).default || files(key); //返回文件实例
        this.$refs.designer.addComponent(content);
      }
      //返回文件名 不含后缀名
    });

    this.init();
    this.$forceUpdate();
    // this.his_enableRuleWatch();
    // this.his_enableOptionWatch();
  },
  data() {
    return {

      componentTypes: [
        { value: "MOBILEPAGE", label: "MOBILEPAGE" }
      ],
      focusId: "",
      umsApi: {},
      search: { name: "" },
      dialogShow: false,
      tempOptions: {},
      tempRules: [],
      menuList: [],
      umsForm: form(),
      title: TITLE,
      state: false,
      value: null,
      editor: null,
      err: false,
      type: -1,
      showSaveDialog: false,
      saveLoading: false,
      componentData: {
        uid: "",
        app: "",
        code: "",
        name: "",
        type: "",
        parent_app: "",
        parent_code: "",
        parent_component: "",
      },
      rules: {
        app: [{ required: true, message: "请输入应用编码", trigger: "blur" }],
        code: [{ required: true, message: "请输入组件编码", trigger: "blur" }],
        name: [{ required: true, message: "请输入组件名称", trigger: "blur" }]
      },
      defaultOption: {
        form: {
          inline: false,
          hideRequiredAsterisk: false,
          labelPosition: "right",
          size: "mini",
          labelWidth: "125px",
          formCreateSubmitBtn: true,
          formCreateResetBtn: false,
        },
        submitBtn: false,
      }
    };
  },
  watch: {
    state(n) {
      if (!n) {
        this.value = null;
        this.err = false;
      }
    },
    value() {
      this.load();
    },
    focusId(val) {
      console.log("focusId", val);
      this.$refs.componentsTreeRef.filter(val);
    },
    $route: {
      handler: function (val) {
        this.url = val.path;
        this.init();
        this.$forceUpdate();
      }
    }
  },
  methods: {
    clearInput() {
      this.clearFocusIdAndNodeClickData();
    },
    async getConfig() {
      let routeKey = "config/setRouter";
      let route = this.$store.getters.router;
      if (utils.isNull(route)) {
        route = localStorage.getItem(routeKey);
        if (!utils.isNull(route)) {
          route = JSON.parse(JSON.stringify(route));
          this.$store.dispatch(routeKey, JSON.parse(route));
        } else {
          let res = await dao.getConfig("config", "route");
          if (res.code == 200) {
            this.$store.dispatch("config/setRouter", res.data);
          }
        }
      }
      let pageKey = "config/setPage";
      let page = this.$store.getters.page;
      if (utils.isNull(page)) {
        page = localStorage.getItem(pageKey);
        if (!utils.isNull(page)) {
          page = JSON.parse(JSON.stringify(page));
          this.$store.dispatch(pageKey, JSON.parse(page));
        } else {
          let res2 = await dao.getConfig("config", "page");
          if (res2.code == 200) {
            this.$store.dispatch("config/setPage", res2.data);
          }
        }
      }
    },
    async init() {
      await this.getConfig();
      var intnList = await createMobileMenu();
      intnList.map(v => {
        v.list.map(m => {
          this.$refs.designer.addComponent(m);
        })
      })
      this.menuList = intnList
      this.$refs.designer.menuList = JSON.parse(JSON.stringify(this.menuList));
      this.$forceUpdate();
      var params = this.$route.params;
      if (params.app != undefined && params.app != null
        && params.code != undefined && params.code != null) {
        var search = {
          app: params.app,
          code: params.code
        };
        const res = await getComponent(search);
        if (res.success && res.data.length != 0) {
          let resData = res.data[0];
          this.componentData.uid = resData.uid;
          this.componentData.app = resData.app;
          this.componentData.code = resData.code;
          this.componentData.name = resData.name;
          this.componentData.type = resData.type;
          this.componentData.parent_app = resData.parent_app;
          this.componentData.parent_code = resData.parent_code;
          this.componentData.parent_component = resData.parent_component;
          this.$refs.designer.setRule(JSON.parse(resData.rule));
          this.$refs.designer.setOption(JSON.parse(resData.options));
        } else {
          this.resetComponentData();
        }
      } else {
        this.resetComponentData();
      }
      this.$nextTick(() => {
        this.his_initComponent = {
          rule: this.$refs.designer.getRule(),
          option: this.$refs.designer.getOption(),
        };
        this.his_enableRuleWatch();
        this.his_enableOptionWatch();
      });
      this.$store.dispatch("api/setAPi", this.$refs.designer.dragForm.api);
    },
    resetComponentData() {
      this.componentData.uid = "";
      this.componentData.app = "";
      this.componentData.code = "";
      this.componentData.name = "";
      this.componentData.type = "PAGE";
      this.componentData.parent_app = "";
      this.componentData.parent_code = "";
      this.componentData.parent_component = "";
      this.$refs.designer.setRule([]);
      this.$refs.designer.setOption(this.defaultOption);
      window.location.href = `${window.location.origin}${window.location.pathname}#/mobile_designer`;
    },
    hookHanlder() {
      this.$refs.designer.form.rule = this.umsForm;
    },
    load() {
      let val;
      if (this.type === 2) {
        val = this.value;
      } else if (this.type === 0) {
        val = formCreate.toJson(this.value, 2);
      } else {
        val = JSON.stringify(this.value, null, 2);
      }
      this.$nextTick(() => {
        this.editor = CodeMirror(this.$refs.editor, {
          lineNumbers: true,
          mode: this.type === 2 ? { name: "vue" } : "application/json",
          gutters: ["CodeMirror-lint-markers"],
          lint: true,
          line: true,
          tabSize: 2,
          lineWrapping: true,
          value: val || "",
        });
        this.editor.on("blur", () => {
          this.err = this.editor.state.lint.marked.length > 0;
        });
      });
    },
    reLoadJosn() {
      // 重新加载配置
      this.$refs.designer.setRule(this.$refs.designer.getRule());
    },
    setJson() {
      this.state = true;
      this.type = 3;
      this.value = [];
    },
    setOption() {
      this.state = true;
      this.type = 4;
      this.value = { form: {} };
    },
    showJson() {
      this.state = true;
      this.type = 0;
      this.value = this.$refs.designer.getRule();
    },
    showOption() {
      this.state = true;
      this.type = 1;
      this.value = this.$refs.designer.getOption();
    },
    showTemplate() {
      this.state = true;
      this.type = 2;
      this.value = this.makeTemplate();
    },
    async handleSearch() {
      this.menuList = await createMobileMenu();
      console.log("搜索", this.menuList);
      const that = this;
      that.menuList.forEach((menu) => {
        menu.list = menu.list.filter(
          (item) =>
            item.label.indexOf(that.search.name) > -1 ||
            item.name.indexOf(that.search.name) > -1
        );
      });
      this.$refs.designer.menuList = JSON.parse(JSON.stringify(this.menuList));
      this.$forceUpdate();
    },
    makeTemplate() {
      const rule = this.$refs.designer.getRule();
      const opt = this.$refs.designer.getOption();
      const temp = `<template>
        <form-create
        v-model="fapi"
        :rule="rule"
        :option="option"
        @submit="onSubmit"
        ></form-create>
        </template>
        <script>
        import formCreate from "@form-create/element-ui";
        export default {
          data () {
            return {
              fapi: null,
              rule: formCreate.parseJson('${formCreate
          .toJson(rule)
          .replaceAll("\\", "\\\\")}'),
              option: formCreate.parseJson('${JSON.stringify(opt)}')
            }
          },
          methods: {
            onSubmit (formData) {
              //todo 提交表单
              }
            }
          }
        <\\/script>`;
      return temp.replace(/[\\]/g, "");
    },
    onOk() {
      if (this.err) return;
      const json = this.editor.getValue();
      let val = JSON.parse(json);
      if (this.type === 3) {
        if (!Array.isArray(val)) {
          this.err = true;
          return;
        }
        var oldRule = this.$refs.designer.getRule();
        var newRule = formCreate.parseJson(json);
        var nowRule = oldRule.concat(newRule);
        this.$refs.designer.setRule(nowRule);
      } else {
        if (!is.Object(val) || !val.form) {
          this.err = true;
          return;
        }
        this.$refs.designer.setOption(val);
      }
      this.state = false;
    },
    showSaveModal() {
      if (this.$refs.designer.getRule().length == 0) {
        this.$message.error("表单没有内容");
        this.colseShowSaveModal();
        return;
      }
      this.saveLoading = false;
      this.showSaveDialog = true;
    },
    colseShowSaveModal() {
      this.showSaveDialog = false;
    },
    saveForm() {
      this.$refs["componentForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.doSave();
      });
    },
    async doSave() {
      var datas = {};
      datas.uid = this.componentData.uid;
      datas.app = this.componentData.app;
      datas.name = this.componentData.name;
      datas.code = this.componentData.code;
      datas.type = this.componentData.type;
      datas.parent_app = this.componentData.parent_app;
      datas.parent_code = this.componentData.parent_code;
      datas.parent_component = this.componentData.parent_component;
      datas.rule = JSON.stringify(this.$refs.designer.getRule());
      datas.options = JSON.stringify(this.$refs.designer.getOption());
      this.saveLoading = true;
      var res = await saveComponent(datas)
        .then()
        .catch(() => {
          this.$message.error("保存失败");
        })
        .finally(() => {
          this.saveLoading = false;
        });

      if (res.success) {
        this.$message.success("保存成功");
        if (res.data.length > 0) {
          this.componentData.uid = res.data[0].uid;
        }
        //带参数重新加载当前窗口
        window.location.href = `#/mobile_designer/${this.componentData.app}/${this.componentData.code}`;
        this.colseShowSaveModal();
        window.location.reload(true);
      } else {
        this.$message.error("保存失败");
      }
    },
    async openExample() {
      //打开新窗口
      if (!this.componentData.uid) {
        return this.$message.error("请先保存");
      }
      window.open(
        `#/view/${this.componentData.app}/${this.componentData.code}`
      );
    },
    focusSearch() {
      this.$refs.designer.dragForm.api
        .all()
        .filter(
          (item) => item.name == this.focusId || item.field == this.focusId
        )
        .forEach((item) => {
          if (item.config && item.config.config.drag) {
            let children = this.findChildrenDragTool(item);
            children.__fc__.el.active();
          } else {
            let parent = this.findParentDragTool(item.__fc__);
            if (parent) {
              parent.el.active();
            }
          }
        });
    },
    findParentDragTool(item) {
      if (item.parent) {
        if (item.parent.type == "dragTool") {
          return item.parent;
        } else {
          return this.findParentDragTool(item.parent);
        }
      }
      return undefined;
    },
    findChildrenDragTool(item) {
      if (item.children[0]) {
        if (item.children[0].type == "DragTool") {
          return item.children[0];
        } else {
          return this.findChildrenDragTool(item.children[0]);
        }
      }
      return undefined;
    },
  },
};
</script>
  
<style lang="scss" scoped>
.main::-webkit-scrollbar {
  width: 6px;
  /* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/
  height: 4px;
  background: #007acc;
}

.yes-de-header {
  height: 60px;
  /* margin: 0 20px; */
  right: 0;
  position: absolute !important;
  display: flex;
  align-items: center;
  cursor: default;
}

.yes-menu-search {
  flex: 1;
  display: flex;
  margin-right: 10px;
}

.yes-menu-main {
  flex: 2;
  display: flex;
  justify-content: flex-end;
}

.script-main {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
}

.script-tabs {
  flex: 1;
  flex-direction: column;
  border: 2px solid #eee;
  /* height: 600px; */
  overflow: auto;
}

.script-editor {
  flex: 6;
  flex-direction: column;
  border: 5px solid #eee;
  /* height: 800px; */
}

.script-editor-tabs {
  margin: 10px 10px 10px 10px;
}

.script-type {
  margin: 10px 10px 10px 10px;
}

body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex !important;
  flex-direction: column !important;
}

#app {
  display: flex;
  flex-direction: column;
  flex: 1;
}

._fc-copyright {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  border-top: 1px solid #ececec;
  background-color: #fff;
  cursor: pointer;
}

._fc-t-dialog .CodeMirror {
  height: 450px;
}

._fc-t-dialog .CodeMirror {
  height: 450px;
}

._fc-t-dialog .CodeMirror-line {
  line-height: 16px !important;
  font-size: 13px !important;
}

.CodeMirror-lint-tooltip {
  z-index: 2021 !important;
}

._fc-t-dialog .el-dialog__body {
  padding: 0px 20px;
}

._fc-b-item {
  display: flex;
}
</style>
<style scoped>
.el-tab-pane {
  height: calc(100vh - 80px);
}
</style>
  