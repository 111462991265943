export default{
  data() {
    return {
      his_componentHistory: [],//存储历史记录
      his_rule: '',//用来比较是否有变化
      his_option: '',//用来比较是否有变化
      his_operateHistoryIndex: -1,
      his_operateHistoryFlag: false,
      his_adjustFlag:false,
      his_watchRule: [],
      his_watchOption: {},
      his_unWatchRule: '',
      his_unWatchOption: '',
      his_initComponent:{}
    }
  },
  methods: {
    his_event_bind () {
      document.addEventListener('keydown', e => {
        if ((e.metaKey || e.ctrlKey) && e.keyCode === 90) {//ctrl+z,上一步,和原有的有冲突，不用
          this.his_previous();
        }
        if ((e.metaKey || e.ctrlKey) && e.keyCode === 89) {//ctrl+y,下一步,和原有的有冲突,不用
          this.his_next();
        }
      });
    },
    his_adjustHistory(){//重新调整历史记录
      if (this.his_adjustFlag) {
        this.his_adjustFlag=false;
        if (this.his_operateHistoryIndex>=(this.his_componentHistory.length-1)) {
          return;
        }
        if (this.his_operateHistoryIndex<0) {
          this.his_componentHistory=[]
          return;
        }
        this.his_componentHistory=this.his_componentHistory.slice(0,this.his_operateHistoryIndex+1)//从start开始，到end结束，不包括end，不会修改原数组
      } 
    },
    //开启监听组件变化
    his_enableRuleWatch () {
      this.his_watchRule = this.$refs.designer.dragForm;
      this.his_unWatchRule = this.$watch("his_watchRule", () => {
        this.focusId="";
        if (!this.his_operateHistoryFlag) {
        const history = {
          rule: this.$refs.designer.getRule(),
          option: this.$refs.designer.getOption()
        }
        const rule = JSON.stringify(history.rule)
        if (this.his_rule !== rule) {
          this.his_adjustHistory();
          this.his_rule = rule;
          this.his_componentHistory.push(history);
          this.his_operateHistoryIndex = this.his_componentHistory.length-1;
        }
        console.log("watchRule变化了:", this.his_operateHistoryIndex);

      }
      },
        {
          deep: true,
          // immediate: true,
        }
      );
    },
    //开启监听表单配置变化
    his_enableOptionWatch () {
      this.his_watchOption = this.$refs.designer.form
      console.log(this.his_watchOption)
      this.his_unWatchOption = this.$watch("his_watchOption", () => {
        if(!this.his_operateHistoryFlag){
          const history = {
            rule: this.$refs.designer.getRule(),
            option: this.$refs.designer.getOption()
          }
          const option = JSON.stringify(history.option)
          if (this.his_option !== option) {
            this.his_adjustHistory();
            this.his_option = option
            this.his_componentHistory.push(history)
            this.his_operateHistoryIndex = this.his_componentHistory.length-1;
          }
          console.log("watchOption变化了:", this.his_operateHistoryIndex);
        }
      },
        {
          deep: true,
          // immediate: true,
        }
      );
    },
     his_previous () {//撤回
      if (this.his_componentHistory.length === 0||this.his_operateHistoryIndex<0) {
        return;
      }
      
      if (this.his_operateHistoryIndex >= 0) {
        this.his_operateHistoryIndex--;
        this.his_operateHistoryFlag =true;
        //this.his_unWatchRule();
        //this.his_unWatchOption();
        const data = this.his_componentHistory[this.his_operateHistoryIndex];
        let component = {}
        if(data){
          component=JSON.parse(JSON.stringify(data))
        }else{
          component=JSON.parse(JSON.stringify(this.his_initComponent))
        }
        this.$refs.designer.setRule(component.rule);
        this.$refs.designer.setOption(component.option);
        this.$nextTick(() => {
          this.his_operateHistoryFlag =false;
          this.his_adjustFlag=true;
          //this.his_enableRuleWatch();
          //this.his_enableOptionWatch();
        })
      }
    },
    his_next () {//还原
      if (this.his_componentHistory.length === 0||this.his_operateHistoryIndex>=(this.his_componentHistory.length-1)) {
        return;
      }
      const maxIndex = this.his_componentHistory.length - 1;
      if (this.his_operateHistoryIndex >= maxIndex) {
        return;
      }

      
      if (this.his_operateHistoryIndex < maxIndex) {
        this.his_operateHistoryIndex++;
        //this.his_unWatchRule();
        //this.his_unWatchOption();
        this.his_operateHistoryFlag =true;
        const componentHis = JSON.parse(JSON.stringify(this.his_componentHistory[this.his_operateHistoryIndex]));
        this.$refs.designer.setRule(componentHis.rule);
        this.$refs.designer.setOption(componentHis.option);
        this.$nextTick(() => {
          this.his_operateHistoryFlag =false;
          this.his_adjustFlag=true;
          //this.his_enableRuleWatch();
          //this.his_enableOptionWatch();
        })
      }
    },
  },
}
