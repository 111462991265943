// 创建axios实例
import axios from "axios";
import store from "../store";
import { messageError, getHeader } from "../api/core/utils";

const instance = function requset(config) {
  let header = {
    "x-frame-options": "SAMEORIGIN",
    "content-type": "application/json;charset=utf-8"
  };

  header = (getHeader() != {} && getHeader() != undefined) ? Object.assign(header, getHeader()) : header;
  config.headers = header;
  config.responseType = "json";
  config.timeout = 1800000;
  config.withCredentials = true;

  return new Promise((res) => {
    axios(config)
      .then(function (success) {
        res(success.data);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          if (window.parent) {
            window.parent.location.href = window.parent.location.origin + store.getters.page.login
          } else {
            window.location.href = window.location.origin + store.getters.page.login
          }
          messageError("登入超时，请重新登入");
          return;
        }
        if (error.response.status === 404) {
          messageError("请求404,服务器路径找不到,请联系管理员处理");
          return;
        }
        messageError(error.response.data.message);
        res(error.response.data);
      });
  });
};

export default instance;
