const state = {
  api:{}
}

const mutations = {
     SETAPI: (state, api) => {
    state.api = api
  }
}

const actions = {
  setAPi({ commit }, api) {
    commit('SETAPI', api)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
