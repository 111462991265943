export function getOptions() {
    return [
        {
            label: '大号字体',
            value: 'font-big'
        },
        {
            label: '中号字体',
            value: 'font-small'
        },
        {
            label: '小号字体',
            value: 'font-small'
        }
    ];
}