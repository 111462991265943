export default {
  data() {
    return {
      scr_activeName: "design",
      scr_scriptTypeTabs: "components",
      scr_typeTreeData: [],
      scr_eventTreeData: [],
      scr_defaultProps: {
        children: "children",
        label: "name",
      },
      scr_monacoRef: null,
      scr_scriptActiveName: "",
      scr_editableTabs: [],
      scr_monacoContent: "",
      scr_monacoScriptName: "",
      scr_monacoScriptEvent: "",
      scr_monacoScriptPath: "",
      scr_allArry: [],
      scr_newData: [],
      scr_resultRule: [],
      scr_componentName: "",
      scr_expanded: [],
      type_treeRef: null,
      event_treeRef: null,
      scr_options: {
        value: "",
        readOnly: false, // 是否可编辑 // 是否为只读模式
        language: "javascript", // 语言类型
        acceptSuggestionOnCommitCharacter: true, // 接受关于提交字符的建议
        acceptSuggestionOnEnter: "on", // 接受输入建议 "on" | "off" | "smart" //-如果设置off 编辑器上的代码补全显示了,但却不补上
        accessibilityPageSize: 10, // 辅助功能页面大小 Number 说明：控制编辑器中可由屏幕阅读器读出的行数。警告：这对大于默认值的数字具有性能含义。
        accessibilitySupport: "off", // 辅助功能支持 控制编辑器是否应在为屏幕阅读器优化的模式下运行。
        autoClosingBrackets: "always", // 是否自动添加结束括号(包括中括号) "always" | "languageDefined" | "beforeWhitespace" | "never"
        autoClosingDelete: "always", // 是否自动删除结束括号(包括中括号) "always" | "never" | "auto"
        autoClosingOvertype: "always", // 是否关闭改写 即使用insert模式时是覆盖后面的文字还是不覆盖后面的文字 "always" | "never" | "auto"
        autoClosingQuotes: "always", // 是否自动添加结束的单引号 双引号 "always" | "languageDefined" | "beforeWhitespace" | "never"
        autoIndent: "None", // 控制编辑器在用户键入、粘贴、移动或缩进行时是否应自动调整缩进
        automaticLayout: false, // 自动布局
        foldingStrategy: "indentation", // 折叠方式  auto | indentation
        codeLens: false, // 是否显示codeLens 通过 CodeLens，你可以在专注于工作的同时了解代码所发生的情况 – 而无需离开编辑器。 可以查找代码引用、代码更改、关联的 Bug、工作项、代码评审和单元测试。
        codeLensFontFamily: "", // codeLens的字体样式
        codeLensFontSize: 14, // codeLens的字体大小
        colorDecorators: false, // 呈现内联色彩装饰器和颜色选择器
        comments: {
          ignoreEmptyLines: true, // 插入行注释时忽略空行。默认为真。
          insertSpace: true, // 在行注释标记之后和块注释标记内插入一个空格。默认为真。
        }, // 注释配置
        contextmenu: true, // 启用上下文菜单
        columnSelection: false, // 启用列编辑 按下shift键位然后按↑↓键位可以实现列选择 然后实现列编辑
        autoSurround: "never", // 是否应自动环绕选择
        copyWithSyntaxHighlighting: true, // 是否应将语法突出显示复制到剪贴板中 即 当你复制到word中是否保持文字高亮颜色
        cursorBlinking: "Solid", // 光标动画样式
        cursorSmoothCaretAnimation: true, // 是否启用光标平滑插入动画  当你在快速输入文字的时候 光标是直接平滑的移动还是直接"闪现"到当前文字所处位置
        cursorStyle: "UnderlineThin", // "Block"|"BlockOutline"|"Line"|"LineThin"|"Underline"|"UnderlineThin" 光标样式
        cursorSurroundingLines: 0, // 光标环绕行数 当文字输入超过屏幕时 可以看见右侧滚动条中光标所处位置是在滚动条中间还是顶部还是底部 即光标环绕行数 环绕行数越大 光标在滚动条中位置越居中
        cursorSurroundingLinesStyle: "all", // "default" | "all" 光标环绕样式
        cursorWidth: 2, // <=25 光标宽度
        minimap: {
          enabled: true, // 是否启用预览图
        }, // 预览图设置
        folding: true, // 是否启用代码折叠
        links: true, // 是否点击链接
        overviewRulerBorder: false, // 是否应围绕概览标尺绘制边框
        renderLineHighlight: "gutter", // 当前行突出显示方式
        roundedSelection: false, // 选区是否有圆角
        scrollBeyondLastLine: false, // 设置编辑器是否可以滚动到最后一行之后
      },
    };
  },
  methods: {
    filterData(data, path = "") {
      if (data == "" || data == undefined) {
        return;
      }
      //如果不是子节点循环清空重新计数

      for (let i in data) {
        for (let key in data[i].props) {
          //过滤事件
          if (key.indexOf("Event") != -1) {
            let obj = data[i];
            obj.path = path;
            obj.eventName = key;
            if (obj.props[key] != "") {
              obj.hasValue = true;
            } else {
              obj.hasValue = false;
            }
            //滤除children
            let arr = ["children"];
            let result = Object.keys(obj)
              .filter((k) => !arr.includes(k))
              .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});
            this.scr_newData.push(result);
          }
        }
        if (data[i].children) {
          //非子节点进入循环时记录rule的index
          //进入下一级筛选之前记录当前的index数组
          this.filterData(
            data[i].children,
            path == "" ? `${i}` : `${path}.${i}`
          );
        }
      }
      return;
    },
    async changeRule() {
      // if (!isChildren) {
      //   this.scr_resultRule = rule;
      // }
      // for (let i in rule) {
      //   let componentsName =
      //     rule[i].name == undefined ? rule[i].field : rule[i].name;
      //   if (
      //     this.scr_monacoScriptName != "" &&
      //     componentsName == this.scr_monacoScriptName
      //   ) {
      console.log("name", this.scr_monacoScriptName);
      await this.$refs.designer.dragForm.api
        .all()
        .filter(
          (item) =>
            item.field == this.scr_monacoScriptName ||
            item.name == this.scr_monacoScriptName
        )
        .forEach((item) =>
          this.$nextTick(() => {
            item.props[this.scr_monacoScriptEvent] = this.scr_monacoContent;
          })
        );
      //   this.typeLoad();
      //   this.eventLoad();
      // } else if (rule[i].children) {
      //   this.changeRule(rule[i].children, true);
      // }
      // }
    },
    handleNodeEventClick(a) {
      if (a.children) {
        return;
      }
      let aName = a.eventName;
      this.scr_monacoScriptName = a.customName;
      this.scr_monacoScriptEvent = aName;
      let newTitle = a.customName + "-" + a.eventName;
      if (a.content != undefined) {
        for (let i in this.scr_editableTabs) {
          if (this.scr_editableTabs[i].title == newTitle) {
            this.scr_scriptActiveName = a.customName + aName;
            this.$nextTick(() => {
              this.scr_monacoContent = a.content;
            });
            return;
          }
        }
        this.scr_editableTabs.push({
          event: aName,
          customName: a.customName,
          name: a.customName + aName,
          title: newTitle,
          content: a.content,
        });
        this.scr_scriptActiveName = a.customName + aName;
        this.$nextTick(() => {
          this.scr_monacoContent = a.content;
        });
      }
    },
    eventLoad() {
      let rule = this.$refs.designer.getRule();
      let eventData = [];
      this.scr_newData = [];
      this.filterData(rule);
      let checkFlag = false;
      let typeCheckFlag = false;
      let index = "";
      let typeINdex = "";
      for (let i in this.scr_newData) {
        let customName =
          this.scr_newData[i].name == undefined
            ? this.scr_newData[i].field
            : this.scr_newData[i].name;
        let eventName = this.scr_newData[i].eventName;
        let hasValue = this.scr_newData[i].hasValue;
        let type = this.scr_newData[i].type;

        //第一级如果不存在此事件类型树 建新树
        for (let j in eventData) {
          if (eventData[j].name == eventName) {
            checkFlag = true;
            index = j;
            break;
          } else {
            checkFlag = false;
          }
        }
        //第二级找到事件分组，分组中是否存在相同组件名？
        for (let k in eventData) {
          if (eventData[k].name == eventName) {
            for (let l in eventData[k].children) {
              if (eventData[k].children[l].type == type) {
                typeCheckFlag = true;
                typeINdex = l;
                break;
              } else {
                typeCheckFlag = false;
              }
            }
          }
        }
        if (!checkFlag) {
          eventData.push({
            name: eventName,
            type: type,
            children: [
              {
                name: type,
                type: type,
                children: [
                  {
                    name: hasValue ? "*  " + customName : customName,
                    customName: customName,
                    eventName: eventName,
                    type: type,
                    content: this.scr_newData[i].props[eventName],
                  },
                ],
              },
            ],
          });
          //如果存在就在第二级别加入
        } else {
          let addChildren = {
            name: type,
            type: type,
            children: [
              {
                name: hasValue ? "*  " + customName : customName,
                customName: customName,
                eventName: eventName,
                type: type,
                content: this.scr_newData[i].props[eventName],
              },
            ],
          };
          let addTypeChildren = {
            name: hasValue ? "*  " + customName : customName,
            customName: customName,
            eventName: eventName,
            type: type,
            content: this.scr_newData[i].props[eventName],
          };
          if (typeCheckFlag) {
            eventData[index].children[typeINdex].children.push(addTypeChildren);
          } else {
            eventData[index].children.push(addChildren);
          }
        }
      }
      this.scr_eventTreeData = eventData;
    },
    typeLoad() {
      let rule = this.$refs.designer.getRule();
      let typeData = [];
      this.scr_newData = [];
      this.filterData(rule);
      let checkFlag = false;
      let typeCheckFlag = false;
      let typeINdex = "";
      let index = "";
      for (let i in this.scr_newData) {
        let customName =
          this.scr_newData[i].name == undefined
            ? this.scr_newData[i].field
            : this.scr_newData[i].name;
        let eventName = this.scr_newData[i].eventName;
        let hasValue = this.scr_newData[i].hasValue;
        let type = this.scr_newData[i].type;
        //如果不存在此组件类型树 建新树
        for (let j in typeData) {
          if (typeData[j].name == type) {
            checkFlag = true;
            index = j;
            break;
          } else {
            checkFlag = false;
          }
        }
        //第二级找到类型分组，分组中是否存在相同组件名？
        for (let k in typeData) {
          if (typeData[k].name == type) {
            for (let l in typeData[k].children) {
              if (typeData[k].children[l].cName == customName) {
                typeCheckFlag = true;
                typeINdex = l;
                break;
              } else {
                typeCheckFlag = false;
              }
            }
          }
        }
        if (!checkFlag) {
          typeData.push({
            name: type,
            children: [
              {
                cName: customName,
                name: hasValue ? "*  " + customName : customName,

                children: [
                  {
                    name: hasValue ? "*  " + eventName : eventName,
                    customName: customName,
                    eventName: eventName,
                    content: this.scr_newData[i].props[eventName],
                    path: this.scr_newData[i].path,
                  },
                ],
              },
            ],
          });
          //如果存在就在第二级别加入
        } else {
          let addChildren = {
            cName: customName,
            name: hasValue ? "*  " + customName : customName,
            children: [
              {
                name: hasValue ? "*  " + eventName : eventName,
                customName: customName,
                eventName: eventName,
                content: this.scr_newData[i].props[eventName],
                path: this.scr_newData[i].path,
              },
            ],
          };
          let addTypeChildren = {
            name: hasValue ? "*  " + eventName : eventName,
            customName: customName,
            eventName: eventName,
            content: this.scr_newData[i].props[eventName],
            path: this.scr_newData[i].path,
          };
          if (typeCheckFlag) {
            typeData[index].children[typeINdex].children.push(addTypeChildren);
          } else {
            typeData[index].children.push(addChildren);
          }
        }
      }
      this.scr_typeTreeData = typeData;
    },
    scriptTypeTabClick(tab) {
      if (tab.name != "event") {
        this.typeLoad();
      } else {
        this.eventLoad();
      }
    },
    mainTabClick(tab) {
      if (tab.name == "script") {
        this.typeLoad();
        this.eventLoad();
      }
    },
    async scriptTabClick(a) {
      let compName = a.label.split("-")[0];
      for (let i in this.scr_editableTabs) {
        if (this.scr_editableTabs[i].name == a.name) {
          (this.scr_monacoScriptName = compName),
            (this.scr_monacoScriptEvent = this.scr_editableTabs[i].event),
            (this.scr_monacoScriptPath = this.scr_editableTabs[i].path);
          let label = a.label.toString();
          let name = label.split("-")[0];
          this.scr_monacoContent = await this.$refs.designer.dragForm.api
            .all()
            .filter((item) => item.field == name || item.name == name)[0].props[
            this.scr_monacoScriptEvent
          ];
        }
      }
    },
    handleNodeClick(a) {
      if (a.children) {
        return;
      }
      // let aName = a.name.replace(/[*\s]/gi, "");
      let aName = a.eventName;
      let newTitle = a.customName + "-" + a.eventName;
      this.scr_monacoScriptName = a.customName;
      this.scr_monacoScriptEvent = aName;
      this.scr_monacoScriptPath = a.path;
      if (a.content != undefined) {
        for (let i in this.scr_editableTabs) {
          if (this.scr_editableTabs[i].title == newTitle) {
            this.scr_scriptActiveName = a.customName + aName;
            this.$nextTick(() => {
              this.scr_monacoContent = a.content;
            });
            return;
          }
        }
        this.scr_editableTabs.push({
          event: aName,
          customName: a.customName,
          name: a.customName + aName,
          title: newTitle,
          content: a.content,
          path: a.path,
        });
        this.scr_scriptActiveName = a.customName + aName;
        this.$nextTick(() => {
          this.scr_monacoContent = a.content;
        });
      }
    },
    removeTab(tabName) {
      if (this.scr_scriptActiveName === tabName) {
        this.scr_editableTabs.forEach((tab, index) => {
          if (tab.name === tabName) {
            let nextTab =
              this.scr_editableTabs[index + 1] ||
              this.scr_editableTabs[index - 1];
            if (nextTab) {
              this.scr_scriptActiveName = nextTab.name;
            }
          }
        });
      }
      this.scr_editableTabs = this.scr_editableTabs.filter(
        (tab) => tab.name !== tabName
      );
    },
    monacoChange(val) {
      if (this.scr_monacoContent == val) {
        return;
      }
      this.scr_monacoContent = val;
      let rule = this.$refs.designer.getRule();
      this.changeRule(rule, false);
    },
    scr_sreachScript() {
      if (this.scr_componentName == "") {
        this.scr_expanded = [];
        this.typeLoad();
        this.eventLoad();
        return;
      }
      this.scr_typeTreeData.forEach((item) => {
        item.children.forEach((i) => {
          if (i.cName.indexOf(this.scr_componentName) != -1) {
            this.scr_expanded.push(i.name);
          }
        });
      });
    },
    scr_clearSearch() {
      this.scr_expanded = [];
      this.typeLoad();
      this.eventLoad();
      return;
    },
  },
};
