import Vue from 'vue'
import VueRouter from 'vue-router'
import Designer from '../views/designer.vue'
import MobileDesigner from '../views/mobileDesigner.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Designer',
    component: Designer,
    meta: {
      title: 'YesDesigner'
    }
  },
  {
    path: '/designer',
    name: 'Designer',
    component: Designer,
    meta: {
      title: 'YesDesigner'
    }
  },
  {
    path: '/designer/:app/:code',
    name: 'Designer',
    component: Designer,
    meta: {
      title: 'YesDesigner'
    }
  },
  {
    path: '/mobile_designer',
    name: 'MobileDesigner',
    component: MobileDesigner,
    meta: {
      title: '手机端设计器'
    }
  },
  {
    path: '/mobile_designer/:app/:code',
    name: 'MobileDesigner',
    component: MobileDesigner,
    meta: {
      title: '手机端设计器'
    }
  },
  {
    path: '/view/:app/:code',
    name: 'view',
    meta: { title: '' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/view.vue')
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
