const state = {
  user:{}
}

const mutations = {
     SETUSER: (state, user) => {
    state.user = user
  }
}

const actions = {
  setUSER({ commit }, user) {
    commit('SETUSER', user)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
