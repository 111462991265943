export function getType(obj){//array,object,string,number,boolean,function,null,undefined
  return Object.prototype.toString.call(obj).slice(8,-1).toLowerCase();
}

export const kebabCase = function(str) {
  const hyphenateRE = /([^-])([A-Z])/g;
  return str
    .replace(hyphenateRE, '$1-$2')
    .replace(hyphenateRE, '$1-$2')
    .toLowerCase();
};